$white: #fff;
$boulder: #777;
$primary: #0072ef;
$danger: #ff3434;
$success: #01e65c;
$black-pearl: #051324;
$background: #000;
$lilywhite: #e8faff;
$scieneblue: #006ade;
$gold: #ffd102;
$malachite: #01e65c;
$primary: #0072ef;

$small: 600px;
$medium: 960px;
$large: 1280px;
$extra-large: 1920px;

body,
html {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  height: auto !important;
}

.container {
  max-width: 1920px;
  box-sizing: border-box !important;
  padding-left: calc(
    10px + (30 - 10) * ((100vw - 320px) / (2560 - 320))
  ) !important;
  padding-right: calc(
    10px + (30 - 10) * ((100vw - 320px) / (2560 - 320))
  ) !important;
  box-sizing: border-box !important;
  left: 0;
  right: 0;
  margin: auto;
  @media only screen and (max-width: 768px) {
    padding: 0 15px !important;
  }
}

.mainBanner {
  width: 100%;
  height: calc(100vh - 140px);
  // padding-top: #{unquote("min(calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320))), 200px)")};
  // padding-bottom: #{unquote("min(calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320))), 200px)")};
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width: 768px) {
    background-position: center right;
  }
  .name {
    font-size: calc(34px + (48 - 34) * ((100vw - 320px) / (2560 - 320)));
    font-weight: 700;
  }
  .description {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (2560 - 320)));
    font-weight: 300;
    line-height: 1.5;
  }

  .searchForm {
    display: flex;
    flex-direction: row;
    background: $white;
    border-radius: 50px;
    align-content: center;
    box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
    .input {
      border: 0px !important;
      box-shadow: none;
    }
  }
}

.addBlocks {
  margin-bottom: -4px;
}

.listItemButton {
  position: relative;
  top: 10px !important;

  .iconSvg {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
  }

  &:hover .iconSvg {
    opacity: 0.7;
    transform: translateX(5px);
  }

  .iconSvg2 {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
  }

  &:hover .iconSvg2 {
    opacity: 1;
    transform: translateX(5px);
  }

  .iconSvg3 {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
    fill: #fff;
  }

  &:hover .iconSvg3 {
    opacity: 1;
    transform: translateX(5px);
  }
}

.listItemButtonBack {
  top: 10px !important;

  position: relative;

  .iconSvg {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
    transform: rotate(180deg);
  }

  &:hover .iconSvg {
    opacity: 0.7;
    transform: translateX(-5px);
  }

  .iconSvg2 {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
  }

  &:hover .iconSvg2 {
    opacity: 1;
    transform: translateX(-5px);
  }

  .iconSvg3 {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(0px);
    fill: #fff;
  }

  &:hover .iconSvg3 {
    opacity: 1;
    transform: translateX(-5px);
  }
}

.myButtonAnimation {
  color: black;

  .textAnimation {
    position: relative;
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 1px;
      background-color: #000000;
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
  }

  &:hover .textAnimation::before {
    left: 0;
    right: auto;
    width: 100%;
  }
}

.myButtonAnimationWhite {
  color: rgb(255, 255, 255) !important;

  .textAnimation {
    position: relative !important;
    display: inline-block !important;

    &::before {
      content: "" !important;
      position: absolute !important;
      bottom: 0 !important;
      right: 0 !important;
      width: 0 !important;
      height: 1px !important;
      background-color: #ffffff !important;
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1) !important;
      z-index: 1 !important;
    }
  }

  &:hover .textAnimation::before {
    left: 0 !important;
    right: auto !important;
    width: 100% !important;
  }
}

.container {
  perspective: 1000px;
}

.cube {
  position: relative;
  width: 200px;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition: transform 1s;
}

.face {
  position: absolute;
  width: 200px;
  height: 200px;
  background: lightgray;
  border: 1px solid black;
}

.face1 {
  transform: rotateY(0deg) translateZ(100px);
}

.face2 {
  transform: rotateY(90deg) translateZ(100px);
}

.cube.rotate {
  transform: rotateY(-90deg);
}

.drawerCloseButton {
  svg {
    fill: #fff !important;
    transition: scale 0.3s ease-in-out;
    scale: 1;
  }

  // .drawerSvg{
  //     transition: scale 0.3s ease-in-out;
  //     scale: 1;
  // }

  &:hover svg {
    scale: 1.4;
  }
}

.shadowBox {
  box-shadow: 0px 10px 25px 2px rgba(0, 0, 0, 0.2);
}

.blackButton {
  width: 100% !important;
  height: 100% !important;
  svg {
    fill: #000;
  }

  &:hover svg {
    opacity: 1;
  }

  a {
    color: #000;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.whiteButton {
  box-shadow: 0 0 0 0 rgb(0, 0, 0), 0 0 0 0 black;

  .MuiButton-root {
    color: #fff;
    background-color: #000;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .MuiButton-root:hover {
    color: #fff;
    background-color: #000;
  }

  svg {
    fill: #fff;
  }

  &:hover svg {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 3px 0 0 black, 0 -3px 0 0 black;
    transition: all 0.2s ease-in-out;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.loadingBtn {
  &:hover {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 0 0 rgba(0, 0, 0, 0.12);
    // transition: all 0.2s ease-in-out;
  }
}

.disabled {
  &:hover {
    box-shadow: none;
  }
}

.css-7s8ysw {
  margin-left: 0px !important;
}

.breadcrumbBox {
  position: relative;
  padding: 1rem;
  background: white;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   // top: -180px;
  //   left: 0;
  //   height: 0px;
  //   width: 100%;
  //   background: red;
  // }
}

.breadcrumbBox ol li a {
  color: white !important;
}

.breadcrumbBox ol li {
  color: white !important;
}

.footer {
  color: #ccc !important;
  font-size: 12px;
  border-top: solid 1px #333;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.standardText {
  .MuiFormLabel-root {
    color: #727272 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    background: #fff !important;
    text-transform: none !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
    padding-right: 4px !important;
    z-index: 1 !important;
    background: #fff !important;
  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important;
    border-radius: 0px 0px 0px 0px !important;
  }
}

.standardTextPayment {
  .MuiFormLabel-root {
    color: #727272 !important;
    // text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    text-transform: none !important;
  }

  .MuiFormLabel-root.MuiInputLabel-shrink {
    color: $primary !important;
    font-weight: 800 !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root.Mui-focused {
    // color: black !important;
    color: $primary !important;
    font-weight: 800 !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important;
    border-radius: 0px 0px 0px 0px !important;

    legend {
      width: 0 !important;
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(5px, -26px) scale(0.75) !important;
    font-weight: 900 !important;
    font-size: 17px !important;
    color: $primary !important;
    text-decoration: none !important;
    text-transform: uppercase !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.standardText2 {
  .MuiFormLabel-root {
    color: #727272 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important;
    border-radius: 0px 0px 0px 0px !important;
  }
}

.checkboxBlack {
  color: #000 !important;
}

.button-grid {
  display: flex;
  flex-wrap: wrap;
}

.button-grid > *:nth-child(5n + 1) {
  clear: both;
}

.optionsButton {
  height: 100% !important;

  .MuiButton-root {
    color: #000 !important;
    background: #fff !important;
    display: flex;
    justify-content: center;
    flex-direction: row;
    border: 1px solid #000;
    padding-left: 5px;
    padding-right: 5px;

    div div div img {
      border: 2px solid #000000 !important;
    }

    &:hover {
      color: #fff !important;
      background-color: #000 !important;
      // scale: 1.1;
      // border-radius: 3px;
      border: 1px solid #000;

      transition: all 0.2s ease-in-out;

      div div div img {
        border: 2px solid #fff !important;
      }
    }
  }
}

.optionsButtonSelected {
  .MuiButton-root {
    color: #ffffff !important;
    background: $primary !important;
    // scale: 1.1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    border: 1px solid $primary;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: none;

    div div div img {
      border: 2px solid #ffffff !important;
    }

    &:hover {
      color: #fff !important;
      background-color: #000 !important;
      // scale: 1.1;
      // border-radius: 3px;
      border: 1px solid #000;

      transition: all 0.2s ease-in-out;
      box-shadow: none;
    }
  }
}

.myButtonAnimation2 {
  color: black;
  font-size: 22px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid $primary !important;
  // padding: 10px 30px !important;
  border-radius: 30px;

  a {
    position: relative;
    z-index: 1;
    color: $primary;
    transition: color 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    text-decoration: none !important;
    font-size: 18px !important;
  }

  .textAnimation {
    position: relative;
    display: inline-block;
    border-radius: 25px;
    overflow: hidden;
    padding: 10px 30px !important;
    width: 100% !important;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 65px;
      background-color: $primary;
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      z-index: 0;
      text-decoration: none !important;
    }
  }

  &:hover {
    a {
      color: #fff;
    }

    .textAnimation::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
}

.tabsSelector {
  color: #000 !important;

  .Mui-selected {
    color: #000 !important;
  }
}

.autocompleteField {
  .MuiInputBase-root {
    height: 100% !important;
  }

  .MuiFormControl-root {
    height: 100% !important;
  }

  .MuiOutlinedInput-root {
    position: relative;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-bottom: 2px solid transparent;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $primary;
      transition: width 0.3s ease-in-out;
      transform: translateX(-50%);
    }

    &.Mui-focused:after {
      width: 100%;
    }
  }

  .MuiInputLabel-root {
    margin-top: 0px !important;
    color: gray !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    text-decoration: none !important;

    &:hover {
      font-weight: 600 !important;
      text-decoration: none !important;
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0px, -26px) scale(0.75) !important;
    font-weight: 900 !important;
    color: $primary !important;
    text-decoration: none !important;
    text-transform: uppercase !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0px !important;
    border-bottom: 1px solid gray !important;
    border-radius: 0px !important;
    font-weight: 600 !important;

    &:hover {
      border-bottom: 1px solid black !important;
    }
  }

  // .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  //   border-bottom: 1px solid black !important;

  // }

  // .css-1idcgzr-MuiInputBase-root-MuiOutlinedInput-root
}

.css-518kzi-MuiListItemSecondaryAction-root {
  position: inherit !important;
  top: 0 !important;
  right: 0px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  align-items: center !important;
  transform: none !important;
}

.methodLabel {
  font-size: 14px !important;
  font-weight: 800 !important;
}

.cart-item {
  .MuiListItemButton-root {
    padding-right: 0px !important;
  }
}

.cart-item {
  .MuiListItemButton-root {
    padding-right: 0px !important;
  }
}

.contact-home {
  a {
    color: $primary !important;
    text-decoration: none !important;
  }
}

.btnLanguage {
  color: #fff !important;
  padding: 0px 5px !important;
  transition: all ease 0.5s !important;
  &:hover {
    color: #004eff !important;
  }

  &.Mui-selected {
    color: #ccc !important;
  }
}

.bannerVideoHome {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin: 30px;
  margin-top: 0px;
  height: 100vh;
  .bgVideo {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .positionContent {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .btn {
    color: #000;
    font-size: 14px;
    text-decoration: none !important;
    background: #eee;
    padding: 10px 25px;
    border-radius: 25px;
    transition: all ease 0.5s;
    &:hover {
      background: #004eff;
      color: #fff;
    }
  }

  .text {
    font-size: calc(25px + (80 - 25) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    text-align: center;
    display: block;
    color: #fff !important;
  }

  @media only screen and (max-width: 768px) {
    margin: 15px;
    // background-color: red;
    border-radius: 15px;
    margin-top: 0px;
  }
}

.TopNavigatorLevel {
  display: inline-flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 50px;
  .item {
    margin: auto 15px;
  }
  a {
    text-transform: none;
    text-decoration: none !important;
    font-size: 14px;
    transition: all ease 0.5s !important;
    & :hover {
      color: #004eff !important;
    }
  }

  @media (max-width: $medium) {
    display: none;
  }
}

.headerContent {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  align-content: center;

  @media (max-width: $medium) {
    justify-content: space-between !important;
  }
}

h2 {
  color: #fafeff;
  font-size: 40px !important;
  font-weight: 700;
}

.services {
  h3 {
    color: #006ade;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .chipServices {
    font-size: 14px;
    padding: 20px 10px !important;
    border-radius: 50px;
    margin: 5px;
    color: #ddd;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      padding: 5px;
      margin: 3px;
    }
  }

  .list {
    padding: 25px 0;
    border-top: solid 1px rgba(112, 112, 112, 0.5);
    &:nth-child(1) {
      border: 0px !important;
    }
  }
}

.paddingGeneral {
  padding-top: calc(
    25px + (200 - 25) * ((100vw - 320px) / (1920 - 320))
  ) !important;
  padding-bottom: calc(
    25px + (200 - 25) * ((100vw - 320px) / (1920 - 320))
  ) !important;
}

.cardPortfolio {
  background: #000 !important;
  border-radius: 30px !important;
  margin-right: 30px;

  @media only screen and (max-width: 768px) {
    border-radius: 15px !important;
    margin: 0px;
  }
  .img {
    width: 100% !important;
    background: none !important;
  }
}

.aboutUs {
  .text {
    font-size: 16px;
    text-align: justify;
  }

  .numbers {
    text-align: center;
    .description {
      font-size: 20px;
    }
    h3 {
      font-size: 100px;
      font-weight: 700;
      color: #004eff;
    }
  }
}

.partner {
  position: relative;
  width: 100%;
  .mask {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  .patnerItem {
    height: 150px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    filter: grayscale(100%);
    transition: all ease 0.5s;
    left: 0;
    right: 0;
    margin: auto;
    margin: 0 10px;
    padding: 0px;
    img {
    }
    &:hover {
      filter: grayscale(0%);
      opacity: 0.75;
    }
  }
}

.contactPage {

  .text {
    font-size: 16px;
    color: #ccc;
  }

  .btnSubmit {
    border-radius: 25px !important;
    text-transform: none !important;
    width: 100% !important;
    padding: 3px;
    box-sizing: border-box;
    margin-top: 20px !important;
    height: 48px !important;
  }

  .input {
    .MuiInputLabel-formControl {
      font-weight: 300 !important;
      text-transform: none !important;
      color: #fff !important;
      font-size: 14px !important;
      margin-top: 10px !important;
      margin-left: 15px;
      z-index: 30 !important;
    }
    .MuiInput-input {
      padding: 15px;
      background: rgba(255, 255, 255, 0.13);
      border-radius: 25px;
      height: 20px !important;
      width: 100%;
    }

    .MuiInputLabel-shrink {
      margin-top: 0px !important;
    }

    .MuiInputBase-root {
      &::before,
      &::after {
        border: 0px !important;
      }
    }

    &.textarea {
      .MuiInput-input {
        height: 150px !important;
      }
    }
  }

  .select {
    .MuiSelect-select {
      border: 0px !important;
      padding: 5px 15px !important;
      background: rgba(255, 255, 255, 0.13);
      border-radius: 25px;
      height: 40px !important;
    }
    .MuiInputLabel-formControl {
      font-weight: 300 !important;
      text-transform: none !important;
      color: #fff !important;
      font-size: 14px !important;
      margin-top: 15px !important;
      margin-left: 3px;
      height: 40px !important;
      z-index: 30 !important;
    }
    .MuiInputBase-root {
      width: 100% !important;
      &::before,
      &::after {
        border: 0px !important;
      }
    }

    .MuiSvgIcon-root {
      color: #fff !important;
      margin-right: 10px;
    }

    .MuiList-root {
      background: #004eff !important;
      border-radius: 0px !important;
      color: #000000;
    }

    .MuiInputLabel-shrink {
      margin-top: 0px !important;
    }
  }
}

.selectOption {
  color: #000000 !important;
}

.addBlocks {
  min-width: 1920px !important;
}

.dialogGeneral {
  .MuiDialog-paper {
    background: #252424 !important;
  }

  .closeButton svg {
    color: #fff;
  }
  .closeButton:hover {
    svg {
      color: $scieneblue;
    }
  }
}
.dialogAdd {
  max-width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0px !important;
    margin: 0px !important;

    h2 {
      font-size: 24px !important;
    }
  }
  .MuiPaper-root {
    max-width: 2000px;
    border-radius: 30px;
    @media only screen and (max-width: 768px) {
    }
  }
  .title {
    color: #000;
    margin-bottom: 10px;
    padding-bottom: 0px;
  }

  .MuiDialog-container {
  
    margin: 0px !important;
    padding: 0px !important;
    left: 0px !important;
    right: 0px !important;
    // background: #000 !important;
  }

  .MuiDialog-paperWidthSm {
    width: 100% !important;
    margin: 10px !important;
    // background: #000 !important;
  }
}

.drawerMenuItems {
  padding: 10px 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.drawerLanguage {
  // max-width: 60px !important;
  width: calc(100% - 36px) !important;
  padding: 0px 18px !important;
  text-transform: uppercase !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;

  div {
    max-width: 90px !important;
  }
}

.languageBlockHeader {
  display: block !important;

  @media (max-width: $medium) {
    display: none !important;
  }
}

.headerMenuBlock {
  display: none !important;

  @media (max-width: $medium) {
    display: block !important;
  }
}

.breadcrumbs {
  color: #fff !important;
  a {
    color: #fff !important;
  }
}

.service-static {
  border-top: solid 1px #333;
  .text {
    color: #fff !important;
  }
}

.numberBox {
  margin-right: 10px !important;
}

.callNowSection {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
  color: #fff !important;

  @media (max-width: $medium) {
    // flex-direction: column !important;
  }
}

.callNowButton {
  border: 1px solid #bdbdbd !important;
  font-size: 14px;
  padding: 10px 20px !important;
  border-radius: 50px;
  margin: 5px;
  text-transform: capitalize !important;
  color: #000 !important;
  text-decoration: none !important;
  background: #fff !important;
  transition: all 0.2s ease-in-out !important;

  &:hover {
    color: #fff !important;
    background: $primary !important;
    transition: all 0.2s ease-in-out;
    border: 1px solid $primary !important;
  }
}

.headerCallNow {
  color: #fff !important;
  font-size: 14px !important;
  text-decoration-color: #fff !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
  border: 1px solid #fff !important;
  padding: 10px 20px 10px 16px !important;
  border-radius: 50px !important;


  &:hover {
    color: $primary !important;
    text-decoration-color: $primary !important;
    transition: all 0.2s ease-in-out !important;
    background: #fff !important;
    color: #000 !important;
  }
}

.emailButton {
  border-radius: 50px !important;
  padding: 10px 20px !important;
}

.emailButtonMain {
  border-radius: 50px !important;
  padding: 10px 20px !important;

  transition: all 0.2s ease-in-out !important;
  background: linear-gradient(-20deg, #01428a, #0072ef) !important;



  &:hover {
    transition: all 0.2s ease-in-out !important;
  }
}



.button112 {
	--width: 500px !important;
	--height: 50px;
	border: 0;
	position: relative;
	min-width: var(--width);
	min-height: var(--height);
	border-radius: var(--height);
	color: #fff;
	font-family: "Montserrat";
	font-weight: bold;
	background: rgba(0, 0, 0, 0.3);
	cursor: pointer;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1rem;

	.text,
	.icon-container {
		position: relative;
		z-index: 2;
	}

	.icon-container {
		height: 25px !important;
    width: 25px !important;
		position: absolute;
    top:13px ;
    right: 13px;
		width: var(--icon-size);
		height: var(--icon-size);
		margin-left: 15px;
		transition: transform 500ms ease;
    opacity: 0 !important; 
		.icon {
			
			svg {
				width: 100%;
				height: 100%;
				fill: #fff;
			}
		}
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $primary !important;
		border-radius: var(--height);
		z-index: 1;
		transition: transform 500ms ease;
	}

	&:hover {
		&::after {
			transform: translateX(calc( 100% - var(--height) - 2px ));
		}

		.icon-container {
      opacity: 1 !important;
      transition: all 0.3s ease-in-out 0.3s;
      // transition: all 0.7s ease-in-out;
			// transform: translateX(200%);
			.icon {
				&--left {
					transform: translateX(20);
					opacity: 1;
				}
				&--right {
					transform: translateX(200%);
					opacity: 0;
				}
			}
		}
	}
}

.support{
	position: absolute;
	right: 10px;
	bottom: 10px;
	padding: 10px;
	display: flex;
	a{
		margin: 0 10px;
		color: #fff;
		font-size: 1.8rem;
		backface-visibility: hidden;
		transition: all 150ms ease;
		&:hover{
			transform: scale(1.1);
		}
	}

}